@import '../../styles/variables';
@import '../../styles/common-imports';

.clientLogo {
    max-width: 200px;
    height: 50px;
    font-weight: bold;
    color: $color-blue;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    text-decoration: none;

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}


@include media-breakpoint-up(xxl) {
    .clientLogo {
        max-width: 250px;
    }
}
