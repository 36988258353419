@import '../styles/variables';
@import '../styles/placeholders';

.paginationInput {
    width: 40px;

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    &[type=number] {
        -moz-appearance: textfield;
    }
}

.dataGridContainer {
    --filter-dropdown-max-height: 300px;
    --thead-max-height: 90px;
    box-shadow: $table-shadow;

    &.hasFilters {
        overflow-x: hidden;
        min-height: calc(var(--filter-dropdown-max-height) + var(--thead-max-height));
    }
}

.tableHeader {
    vertical-align: top;
    text-align: left;
}

.dataGrid {
    min-width: 100%;
    width: auto;
    table-layout: fixed;
    border-radius: $data-grid-radius;
    overflow: hidden;
    margin-bottom: 0;

    :global {
        th {
            white-space: nowrap;
            background-color: $color-blue-oxford;
            font-size: .875rem;
            color: $color-white;
            max-height: var(--thead-max-height);
        }

        @extend %table-styles;

        .btn-light {
            background-color: transparent;
            border-color: transparent;
            color: $color-blue-oxford;

            &:hover {
                background-color: rgba($color-black,.1);
            }
        }

        tr {
            &.hasSubRows td {
                background-color: rgba($color-blue-light,.5);
                border-left-color: transparent;
                border-right-color: transparent;
                min-height: 40px;
            }

            &.hasExpandRows td {
                border-left-color: transparent;
                border-right-color: transparent;

                &:first-child {
                    padding-left: 4px;
                    padding-right: 4px;
                }
            }

            &.isExpandFlatRow td {
                border-left-color: transparent;
                border-right-color: transparent;
                padding-top: 2px;
                padding-bottom: 2px;

                &:nth-child(2) {
                    border-left-width: 2px;
                    border-left-color: $color-blue-light;
                }

                &:not(:first-child) {
                    background-color: rgba($color-blue-light,.2);
                }
            }

            &.hasSubHeaders th:not(.isPlaceholder) {
                border-bottom: 1px solid $color-white;
            }
        }

        td {
            font-size: .875rem;
            white-space: nowrap;
            vertical-align: middle;
        }

        td img {
            height: auto;
            width: auto;
            max-width: 100%;
            max-height: 50px;
        }

    }
}

.subGrid {
    :global {
        th {
            background-color: $color-blue;
        }

        tr {
            &.isFlatRow td {
                background-color: rgba($color-blue-light,.2);
            }
        }
    }
}

.filter {
    width: 100%;
    float: right;
}
.filterSmall {
    width: 50%;
    float: right;
}

.filterParent {
    max-width: fit-content;
}

.filterSelected {
    color: $color-white;
    font-size: 16px;
}

.sortUp {
    top: 3px;
}

.sortDown {
    top: -3px;
}

.noSort {
    width: 14px;
}

.filterItemsParent {
    border-radius: 0;
    border: 1px solid $color-blue-oxford;
    box-shadow: $dropdown-shadow;
    background: white;
    color: black;
    position: absolute;
    top: 29px; // th height
    height: fit-content;
    max-height: var(--filter-dropdown-max-height);
    overflow-y: auto;
    box-sizing: border-box;
    z-index: 10;
}

.dropdown {
    padding: 1px;
}

/* Style the list items inside the dropdown */
.dropdownContent {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        margin: 0;
        padding: 2px 14px;
        color: $color-blue-oxford;
        font-size: 14px;
        cursor: pointer;

        &:hover {
            background-color: $color-blue;
            color: $color-white;
        }
    }

    :global li.active {
        background-color: $color-blue;
        color: $color-white;
    }
}

.pagination {
    font-size: .875rem;
    color: $color-black;
    container: pagination / inline-size;

    :global {
        .btn-link {
            color: $color-blue;
            text-decoration: none;

            &:hover {
                background-color: rgba($color-white,.8);
                box-shadow: $btn-shadow;
            }
        }
    }
}
