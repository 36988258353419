.modalBody {
    min-height: 300px;
}

.imageWrap {
    position: relative;
    width: auto;
    max-width: 100%;
    height: clamp(400px, 100%, 70vh);

    img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
