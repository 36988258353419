@import '../../styles/variables';

.deltaLegend {
    font-size: 14px;
    line-height: 17px;
    height: 17px;

    &.green {
        background-color: $delta-color-green;
    }

    &.red {
        background-color: $delta-color-red;
    }

    &.yellow {
        background-color: $delta-color-yellow;
    }
}

.deltaRed,
.deltaGreen,
.deltaYellow {
    position: relative;
    min-height: 21px;

    &::before,
    &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        content: "";
        width: 9px;
        display: inline-block;
    }

    &::before {
        left: -9px;
    }

    &::after {
        right: -9px;
    }
}

.deltaRed {
    background-color: $delta-color-red;

    &::before,
    &::after {
        background-color: $delta-color-red;
    }
}

.deltaGreen {
    background-color: $delta-color-green;

    &::before,
    &::after {
        background-color: $delta-color-green;
    }
}

.deltaYellow {
    background-color: $delta-color-yellow;

    &::before,
    &::after {
        background-color: $delta-color-yellow;
    }
}

tr td {
    &:first-child {
        .deltaRed,
        .deltaGreen,
        .deltaYellow {
            &::before {
                width: 4px;
                left: -4px;
                border-top-left-radius: $delta-row-radius;
                border-bottom-left-radius: $delta-row-radius;
            }
        }
    }

    &:last-child {
        .deltaRed,
        .deltaGreen,
        .deltaYellow {
            &::after {
                width: 4px;
                right: -4px;
                border-top-right-radius: $delta-row-radius;
                border-bottom-right-radius: $delta-row-radius;
            }
        }
    }
}
