@import '../../../styles/common-imports';

.tooltip {
    --bs-tooltip-max-width: 400px;
}


@include media-breakpoint-up(xl) {
    .tooltip {
        --bs-tooltip-max-width: 600px;
    }
}
