@import '../../styles/variables';
@import '../../styles/common-imports';

.sidebar {
    width: 25vw;
    max-width: $sidebar-width-sm;
    box-shadow: $sidebar-shadow;
    transition: width 0.3s ease-in-out;
    position: relative;
    overflow: hidden;

    &.collapsed {
        width: 65px !important;
    }
}

.logoWrap {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    transition: none;
}

.logo {
    height: 55px;
    width: auto;
    transition: none;
}

.mainNav {
    display: flex;
    flex-direction: column;
}

.collapseLogo {
    position: absolute;
    top: 25px;
    height: 40px;
    width: 35px;
    transition: none;
}

.collapsePanel {
    top: 82px;
    left: calc(100% - 48px);
    transition: left 0.3s ease-in-out;
}

.sidebar.collapsed .collapsePanel {
    left: 8px;
}

@include media-breakpoint-up(xl) {
    .sidebar {
        width: 30vw;
        max-width: $sidebar-width;
    }
}
