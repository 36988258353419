@import '../../styles/variables';
@import '../../styles/common-imports';

.contentWrap {
    background-color: $color-gray-light;
    flex-grow: 1;
    display: flex;
}

.content {
    width: 75vw;
    max-width: calc(100vw - min(#{$sidebar-width-sm}, 25vw));
    transition: width 0.3s ease-in-out, max-width 0.3s ease-in-out;

    &.expandContent {
        max-width: calc(100vw - 65px);
    }
}

.contentInner {
    padding: 20px;
}

.footer {
    padding-top: .75rem;
    padding-bottom: .75rem;
}

.footerText {
    font-size: .875rem;
}

.logo {
    height: 26px;
}

.footerLink {
    text-decoration: none;
    min-width: 0;
}

.pageIdentifier {
    width: calc(100% - 20px);
}


@include media-breakpoint-up(md) {
    .contentInner {
        padding-left: 40px;
        padding-right: 40px;
    }
}

@include media-breakpoint-up(xl) {
    .content {
        width: 70vw;
        max-width: calc(100vw - min(#{$sidebar-width}, 30vw));
    }
}
